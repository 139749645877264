<template>
  <div>
    <el-card>
      <el-form label-width="220px" :model="form" ref="form" :rules="stamRules" class="stamForm" @submit.native.prevent>
        <el-form-item label="印花数量：" prop="rfid_stam_phr">
          <el-input
            v-model="form.rfid_stam_phr"
            ref="stamRef"
            maxlength="9"
            @input="form.rfid_stam_phr = helper.keepTNum(form.rfid_stam_phr)"
            show-word-limit
          >
            <template slot="append">张</template></el-input
          >
        </el-form-item>
        <el-input v-model="form.rfid_stam_phr" style="display: none"> </el-input>
        <el-form-item class="ml_10" align="center">
          <el-button class="determine" type="success" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!--   提交 -->
    <el-dialog :visible.sync="stamDialogVisible" width="70%">
      <h2 class="diaTitle">提示：提交印花信息, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="stamDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="stamDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
export default {
  data() {
    return {
      form: {
        rfid_stam_phr: ''
      },
      stamRules: {
        rfid_stam_phr: [
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' },
          { required: true, message: '请输入印花数量', trigger: 'blur' }
        ]
      },
      stamDialogVisible: false
    };
  },
  props: {
    prop: {
      type: Object
    }
  },
  methods: {
    submit() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.stamDialogVisible = true;
        }
      });
    },
    stamDialog() {
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.rfid_stam_phr = this.form.rfid_stam_phr;

      post(rfidAPI.edit_stam, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          this.$refs.form.resetFields();
          this.$emit('empty');
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
      this.stamDialogVisible = false;
    }
  },
  mounted() {
    this.$EventBus.$on('stam', () => {
      this.form.rfid_stam_phr = '';
    });
    this.$EventBus.$on('stamEmit', res => {
      this.form.rfid_stam_phr = res.rfid_stam_phr;

      if (!this.$refs.stamRef) {
        return;
      } else {
        this.$refs.stamRef.focus();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
::v-deep .stamForm .el-form-item {
  margin-bottom: 10px;
}
::v-deep .stamForm {
  width: 90%;
  margin: 0 auto;
  min-width: 500px;
}
::v-deep .stamForm .el-form-item__label {
  font-size: $font-max;
}
::v-deep .stamForm .el-input {
  height: 60px;
  font-size: $font-max;
}
::v-deep .stamForm .el-input input {
  height: 60px;
}
::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}
</style>
