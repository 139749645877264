<template>
  <div>
    <el-card>
      <el-form ref="form" :model="form" label-width="220px" :rules="compRules" class="compForm" @submit.native.prevent>
        <el-form-item label="复合用量：" prop="rfid_comp_phr">
          <el-input
            v-model="form.rfid_comp_phr"
            ref="compRef"
            maxlength="11"
            show-word-limit
            @input="form.rfid_comp_phr = helper.keepTNum1(form.rfid_comp_phr)"
            @change="compPhrChange"
          >
            <template slot="append">米</template></el-input
          >
        </el-form-item>

        <el-form-item class="ml_10" align="center">
          <el-button @click="submit" type="success" class="determine">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!--   提交 -->
    <el-dialog :visible.sync="compDialogVisible" width="70%">
      <h2 class="diaTitle">提示：提交复合信息, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="compDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="compDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
export default {
  data() {
    return {
      form: {
        rfid_comp_phr: ''
      },
      compRules: {
        rfid_comp_phr: [
          {
            required: true,
            message: '请输入复合用量',
            trigger: 'blur'
          },
          { pattern: /^\d{0,6}(\.\d{1,4})?$/, message: '最大只能输入999999.9999且最多4位小数!' }
        ]
      },
      compDialogVisible: false
    };
  },
  props: {
    prop: {
      type: Object
    }
  },
  methods: {
    submit() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.compDialogVisible = true;
        }
      });
    },
    compDialog() {
      this.compDialogVisible = false;
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.rfid_comp_phr = this.form.rfid_comp_phr;
      post(rfidAPI.edit_comp, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          this.$refs.form.resetFields();
          this.$emit('empty');
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    compPhrChange() {
      // this.form.rfid_comp_phr
    }
  },
  mounted() {
    this.$EventBus.$on('comp', () => {
      this.form.rfid_comp_phr = '';
    });
    this.$EventBus.$on('compEmit', res => {
      this.form.rfid_comp_phr = res.rfid_comp_phr;
      if (!this.$refs.compRef) {
        return;
      } else {
        this.$refs.compRef.focus();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.compForm .el-form-item {
  margin-bottom: 10px;
}
.compForm {
  width: 90%;
  margin: 0 auto;
  min-width: 500px;
}
::v-deep .compForm .el-form-item__label {
  font-size: $font-max;
}
::v-deep .compForm .el-input {
  font-size: $font-max;
  height: 60px;
}
::v-deep .compForm .el-input input {
  height: 60px;
}
::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}
</style>
