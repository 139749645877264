<template>
  <div>
    <el-card>
      <el-form :model="form" :rules="formRule" ref="form" label-width="220px" class="openForm">
        <el-form-item label="开版片数：" prop="rfid_open_phr">
          <el-input
            v-model="form.rfid_open_phr"
            ref="openphrRef"
            maxlength="9"
            show-word-limit
            @keyup.enter.native="openphrKeyup"
            @input="openPhrInput"
          >
            <template slot="append">片</template>
          </el-input>
        </el-form-item>

        <el-form-item label="裁片数量：" prop="rfid_cut_num">
          <el-input v-model="form.rfid_cut_num" ref="opennumRef" maxlength="9" show-word-limit @input="openNumInput">
            <template slot="append">片</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-link type="primary" class="link-smpl" @click="aclick" :disabled="isShow">跳转到样品工单</el-link>
        </el-form-item>

        <el-form-item class="ml_10" align="left">
          <el-button @click="submit()" class="openDetermine" type="success">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!--   提交 -->
    <el-dialog :visible.sync="openDialogVisible" width="50%">
      <h2 class="diaTitle">提示：提交开版信息, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="openDialogVisible = false" class="openCancel">取 消</el-button>
        <el-button type="primary" @click="openDialog" class="openDetermineD">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
export default {
  data() {
    return {
      form: {
        user_id: '',
        stff_id: '',
        stff_name: '',
        rfid_open_phr: '',
        rfid_cut_num: '',
        rfid_mtrl_length: ''
      },
      rfid_open_id: '',
      formRule: {
        rfid_open_phr: [
          { required: true, message: '请输入开版用量', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }
        ],
        rfid_cut_num: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }]
      },
      openDialogVisible: false,
      smpl_id: '',
      isShow: true
    };
  },
  props: {
    prop: {
      type: Object
    }
  },
  methods: {
    submit() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.openDialogVisible = true;
        }
      });
    },
    openDialog() {
      const form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.rfid_open_phr = this.form.rfid_open_phr;
      form.rfid_cut_num = this.form.rfid_cut_num;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.rfid_open_id = this.rfid_open_id;
      post(rfidAPI.edit_open, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.form.rfid_cut_num = '';
          this.form.rfid_open_phr = '';

          this.$emit('empty');
          this.$message.success(res.data.msg);
          this.isShow = true;
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });

      this.openDialogVisible = false;
    },
    openphrKeyup() {
      this.$refs.opennumRef.focus();
    },
    aclick() {
      this.jump('smpl_edit', { perm_id: 112, form_id: this.smpl_id, opendiFlag: 1 });
    },
    openPhrInput(val) {
      this.form.rfid_open_phr = this.helper.keepTNum(val);
    },
    openNumInput(val) {
      this.form.rfid_cut_num = this.helper.keepTNum(val);
    }
  },
  mounted() {
    this.$EventBus.$on('open', () => {
      this.form.rfid_open_phr = '';
      this.form.rfid_cut_num = '';
      this.isShow = true;
    });
    this.$EventBus.$on('openEmit', res => {
      this.form.rfid_open_phr = res.rfid_open_phr;
      this.form.rfid_cut_num = res.rfid_cut_num;
      this.rfid_open_id = res.rfid_open_id;
      this.smpl_id = res.smpl_id;
      this.isShow = false;
      if (!this.$refs.openphrRef) {
        return;
      } else {
        this.$refs.openphrRef.focus();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.openForm {
  width: 90%;
  margin: 0 auto;
  min-width: 500px;
}
.openForm .el-form-item {
  margin-bottom: 20px;
}
::v-deep .openForm .el-form-item__label {
  font-size: $font-max;
}
::v-deep .openForm .el-input {
  height: 60px;
  font-size: $font-max;
}
::v-deep .openForm .el-input input {
  height: 60px;
}
.link-smpl {
  font-size: $font-max;
}
.openDetermine {
  width: 280px;
  height: 80px;
  font-size: 24px;
}
.openCancel {
  width: 180px;
  height: 80px;
  font-size: 24px;
  margin-right: 50px;
}
.openDetermineD {
  width: 180px;
  height: 80px;
  font-size: 24px;
  margin-right: 50px;
}
::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}
.diaContent {
  margin-top: 20px;
}
</style>
