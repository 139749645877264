<template>
  <div>
    <el-card>
      <el-form label-width="220px" :model="form" ref="maseRef" :rules="rules" class="maseForm">
        <el-form-item label="缝纫片数：" prop="rfid_sew_slice">
          <el-input
            v-model="form.rfid_sew_slice"
            @keyup.enter.native="sewSliceClick"
            maxlength="9"
            ref="sew_slice_ref"
            show-word-limit
            @input="form.rfid_sew_slice = helper.keepTNum(form.rfid_sew_slice)"
          >
            <template slot="append">片</template></el-input
          >
        </el-form-item>
        <el-form-item label="配件数量：" prop="rfid_cut_num">
          <el-input v-model="form.rfid_cut_num" ref="opennumRef" maxlength="9" show-word-limit @input="openNumInput">
            <template slot="append">片</template>
          </el-input>
        </el-form-item>
        <el-form-item label="时间：" :rules="[{ required: true }]">
          <el-row :gutter="10">
            <el-col :span="11">
              <el-form-item
                prop="points"
                :rules="form.points && form.seconds ? rules.points : form.seconds ? [{ required: false }] : rules.points"
              >
                <el-input
                  placeholder="请输入内容"
                  v-model="form.points"
                  ref="masepRef"
                  @keyup.enter.native="masepKeyup"
                  maxlength="6"
                  show-word-limit
                  @input="form.points = helper.keepTNum(form.points)"
                >
                  <template slot="append">分</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item
                prop="seconds"
                :rules="form.points && form.seconds ? rules.seconds : form.points ? [{ required: false }] : rules.seconds"
              >
                <el-input
                  placeholder="请输入内容"
                  v-model="form.seconds"
                  ref="masesRef"
                  @keyup.enter.native="masesKeyup"
                  maxlength="2"
                  show-word-limit
                  @input="form.seconds = helper.keepTNum(form.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="备注：" prop="remark">
          <el-row>
            <el-col>
              <el-input
                class="mase_text"
                v-model="form.remark"
                type="textarea"
                maxlength="200"
                show-word-limit
                ref="remark"
                :autosize="{ minRows: 6, maxRows: 8 }"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="文件：">
          <el-upload
            :disabled="!this.prop.rfid_id"
            class="upload-demo"
            ref="uploadRef"
            :action="uploadUrl"
            :on-success="handAvatarSuccess"
            :before-upload="beforeUploadVideo"
            :limit="1"
            :data="dataBody"
            :on-remove="handRemove"
          >
            点击上传
            <div slot="tip" class="el-upload__tip">提示：只能上传一个视频文件或图片文件，且不超过500MB</div>
          </el-upload>
        </el-form-item>
        <el-row v-if="docu_name">
          <el-col>
            <el-form-item>
              <div class="saved">
                <i class="el-icon-document"></i> 已保存文件:
                {{ docu_name + '.' + docu_suffix }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="ml_10" align="center">
          <el-button class="determine" type="success" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!--   提交 -->
    <el-dialog :visible.sync="maseDialogVisible" width="70%">
      <h2 class="diaTitle">提示：提交机缝信息, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="maseDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="maseDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import { docuAPI } from '@api/modules/docu';
import helper from '@assets/js/helper';
export default {
  data() {
    return {
      form: {
        points: '',
        seconds: '',
        remark: '',
        rfid_sew_slice: '',
        rfid_cut_num: ''
      },
      FormRules: {},
      docu_id: '',
      docu_name: '',
      docu_suffix: '',
      uploadUrl: helper.modePrepend(docuAPI.upload_video),
      dataBody: {
        docu_id: ''
      },
      rules: {
        rfid_sew_slice: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        points: [
          { required: true, message: '请输入时间', trigger: 'blur' },
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }
        ],
        seconds: [
          { required: true, message: '请输入时间', trigger: 'blur' },
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      maseDialogVisible: false
    };
  },
  props: {
    prop: {
      type: Object
    }
  },
  methods: {
    submit() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }
      this.$refs['maseRef'].validate(valid => {
        if (valid) {
          this.maseDialogVisible = true;
        }
      });
    },
    maseDialog() {
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.rfid_cut_num = this.form.rfid_cut_num;
      form.rfid_sew_slice = this.form.rfid_sew_slice;
      form.remark = this.form.remark;
      form.rfid_mase_time = this.form.points * 60 - 0 + (this.form.seconds - 0);
      form.docu_id = this.docu_id;

      post(rfidAPI.edit_mase, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          this.$refs.maseRef.resetFields();
          this.docu_name = '';
          this.form.rfid_cut_num = '';
          this.$refs.uploadRef.clearFiles();
          this.$emit('empty');
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
      this.maseDialogVisible = false;
    },
    handAvatarSuccess(response, file, fileList) {
      if (response.code == 0) {
        this.$message.success('上传成功');
        this.docu_id = response.data.docu_id;
      } else {
        this.$message.error('上传失败，请重新上传！');
      }
    },

    beforeUploadVideo(file) {
      const isLt10M = file.size / 1024 / 1024 < 500;
      let fileName = file.name.split('.');
      if (fileName[0].length > 50) {
        this.$message.error('上传失败，文件名字过长! 最大长度50');
        return false;
      }
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/x-ms-wmv',
          'video/mwv',
          'video/rmvb',
          'image/png',
          'image/jpeg',
          'image/gif',
          'image/webp',
          'image/bmp',
          'image/tif'
        ].indexOf(file.type) == -1
      ) {
        this.$message.error('请上传正确的视频格式或图片格式');
        return false;
      }
      if (!isLt10M) {
        this.$message.error('上传视频或图片大小不能超过500MB哦!');
        return false;
      }
    },
    handRemove(res) {
      this.docu_id = '';
    },
    masepKeyup() {
      this.$refs.masesRef.focus();
    },
    masesKeyup() {
      this.$refs.remark.focus();
    },
    sewSliceClick() {
      this.$refs.masepRef.focus();
    },
    openNumInput(val) {
      this.form.rfid_cut_num = this.helper.keepTNum(val);
    }
  },
  mounted() {
    this.$EventBus.$on('mase', () => {
      this.$refs.maseRef.resetFields();
      this.docu_name = '';
      this.docu_suffix = '';
      this.form.rfid_cut_num = '';
      this.$refs.uploadRef.clearFiles();
    });
    this.$EventBus.$on('maseEmit', res => {
      this.form.remark = res.remark;
      if (!res.rfid_mase_time) {
        this.form.points = '';
        this.form.seconds = '';
      } else {
        if (res.rfid_mase_time) {
          this.form.points = parseInt(res.rfid_mase_time / 60) == 0 ? '' : parseInt(res.rfid_mase_time / 60);
        }

        if (res.rfid_mase_time) {
          this.form.seconds = res.rfid_mase_time % 60 == 0 ? '' : res.rfid_mase_time % 60;
        }
      }
      this.form.rfid_sew_slice = res.rfid_sew_slice;
      this.docu_id = res.docu_id;
      this.docu_name = res.docu_name;
      this.docu_suffix = res.docu_suffix;
      this.form.rfid_cut_num = res.rfid_cut_num;

      if (res.docu_id !== null) {
        this.dataBody.docu_id = res.docu_id;
      }

      if (!this.$refs.masepRef) {
        return;
      } else {
        this.$refs.sew_slice_ref.focus();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.maseForm .el-form-item {
  margin-bottom: 20px;
}
.maseForm {
  width: 90%;
  margin: 0 auto;
  min-width: 500px;
}

::v-deep .el-upload--text {
  width: 180px;
  height: 70px;
  font-size: $font-max;
  line-height: 70px;
}

::v-deep .maseForm .el-form-item__label {
  font-size: $font-max;
}
::v-deep .maseForm .el-input {
  height: 60px;
  font-size: $font-max;
}
::v-deep .maseForm .el-input input {
  height: 60px;
}
.el-upload__tip {
  font-size: 24px;
}
.mase_text {
  font-size: $font-max;
}
.saved {
  font-size: $font-max;
}
::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}
</style>
